.container {
    div {
        p {
            width: 120px;
            margin: 0 auto;
        }
        #sticker {
            background-image: url("./middlelands.png");
            background-size: 432px 432px;
            cursor: pointer;
            height: 120px;
            width: 120px;
            background-position: -12px -12px;
            image-rendering: -webkit-optimize-contrast;
            margin: 0 auto;
        }
    }
}
